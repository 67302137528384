import React from 'react';

import { ClassName, I18nText } from '../../../../../types';
import { IconsEnum } from '../../../../../assets/icons/types';

import {
  FetchLifestylesCacheKeys,
  UpdateLifestyleCacheAction,
  UpdateShowLifestyleCacheAction
} from '../../../lifestylesTypes';

import { FetchLifestylesSetsCacheKeys } from '../../../../lifestylesSets/lifestylesSetsTypes';

import { PureTooltipIconButtonHelper } from '../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { useLifestyleFavoriteButton } from './hooks/useLifestyleFavoriteButton';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { LifestyleFavoriteButtonLifestyle } from './LifestyleFavoriteButton.types';

import { words } from '../../../../../locales/keys';

interface LifestyleFavoriteButtonProps {
  className?: ClassName;
  i18nText?: I18nText;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  lifestyle: LifestyleFavoriteButtonLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  updateIndexLifestyleCache?: UpdateLifestyleCacheAction<LifestyleFavoriteButtonLifestyle>;
  updateShowLifestyleCache?: UpdateShowLifestyleCacheAction<LifestyleFavoriteButtonLifestyle>;
}

function LifestyleFavoriteButton({
  className,
  i18nText,
  icon,
  iconClassName,
  lifestyle,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  updateIndexLifestyleCache,
  updateShowLifestyleCache
}: LifestyleFavoriteButtonProps) {
  const { handleToggleFavorite, toggleLifestyleFavoriteLoading } =
    useLifestyleFavoriteButton({
      lifestyle,
      lifestylesCacheKeys,
      lifestylesSetCacheKeys,
      updateIndexLifestyleCache,
      updateShowLifestyleCache
    });

  return (
    <PureTooltipIconButtonHelper
      className={className}
      dataGa="lifestyles-lifestyle-favorite-button"
      icon={icon}
      tooltipI18nText={
        lifestyle.favorite ? words.removeFromFavorites : words.addToFavorites
      }
      i18nText={i18nText}
      tooltipPlacement={TooltipPlacement.TOP}
      onClick={handleToggleFavorite}
      iconClassName={iconClassName}
      disabled={toggleLifestyleFavoriteLoading}
    />
  );
}

export default LifestyleFavoriteButton;
