import React, { memo, useCallback, useMemo } from 'react';
import some from 'lodash/some';

import { OnLightboxOpenAction } from '../../../../../helpers/LightboxWrapper';

import {
  FetchLifestylesSetsCacheKeys,
  UpdateLifestylesSetCacheAction
} from '../../../../lifestylesSets/lifestylesSetsTypes';

import {
  FetchLifestylesCacheKeys,
  LifestyleNanoID,
  OnSelectedLifestylesSidebarCloseAction,
  OnSelectedLifestylesSidebarOpenAction,
  UpdateLifestyleCacheAction
} from '../../../lifestylesTypes';
import {
  LifestylesListItemLifestyle,
  LifestylesListItemLifestylesSet,
  LifestylesListItemOnLifestylesSelect
} from './LifestylesListItem.types';
import { IconsEnum } from '../../../../../assets/icons/types';
import { TeamNanoID } from '../../../../teams/teamsTypes';

import { LifestylesListItemEditLink } from '../LifestylesListItemEditLink';
import { DownloadLifestyleButton } from '../../buttons/DownloadLifestyleButton';
import { LifestylesListItemNda } from './components/LifestylesListItemNda';
import { LifestylesListItemOpenLightboxButton } from './components/LifestylesListItemOpenLightboxButton';
import { LifestylesListItemPreview } from './components/LifestylesListItemPreview';
import { LifestylesListItemUpdateImageVersions } from './components/LifestylesListItemUpdateImageVersions';
import { LifestyleAttachModalButton } from '../../buttons/LifestyleAttachModalButton';
import { LifestyleSelectButton } from '../../buttons/LifestyleSelectButton';
import { LifestyleFavoriteButton } from '../../buttons/LifestyleFavoriteButton';
import { LifestyleCopyLinkButton } from '../../buttons/LifestyleCopyLinkButton';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { TextWithTooltip } from '../../../../../helpers/TextWithTooltip';
import { TooltipSingletonSourceWrapper } from '../../../../../helpers/tooltips/TooltipSingletonSourceWrapper';
import { NextPureTooltipIconLinkHelper } from '../../../../../helpers/links/NextPureTooltipIconLinkHelper';
import { LifestylesListItemUploaded } from './components/LifestylesListItemUploaded';

import { TooltipPlacement } from '../../../../../helpers/tooltips/tooltipsConstants';

import { LifestylesPermissions } from '../../../lifestylesConstants';

import { LifestylePath } from '../../../LifestylePath';
import { words } from '../../../../../locales/keys';
import { TeamPath } from '../../../../teams/TeamPath';

interface LifestylesListItemDefaultProps {
  companyNanoId?: TeamNanoID;
  isMyLibrary?: boolean;
  lifestyle: LifestylesListItemLifestyle;
  lifestylesCacheKeys?: FetchLifestylesCacheKeys;
  onLightboxOpen: OnLightboxOpenAction;
  updateLifestyleCache?: UpdateLifestyleCacheAction<LifestylesListItemLifestyle>;
  onLifestyleEditButtonMouseEnter?: (lifestyleNanoId: LifestyleNanoID) => void;
  withRealAspectRatio?: boolean;
  withLifestylePreviewLink?: boolean;
}

interface LifestylesListItemWithSelectProps {
  lifestylesSet: LifestylesListItemLifestylesSet | null;
  lifestylesSetCacheKeys?: FetchLifestylesSetsCacheKeys;
  onSelectedLifestylesSidebarOpen: OnSelectedLifestylesSidebarOpenAction;
  onSelectedLifestylesSidebarClose: OnSelectedLifestylesSidebarCloseAction;
  updateLifestylesSetCache: UpdateLifestylesSetCacheAction<LifestylesListItemLifestylesSet>;
  onLifestylesSelect?: LifestylesListItemOnLifestylesSelect;
}

interface LifestylesListItemWithoutSelectProps {
  lifestylesSet?: never;
  lifestylesSetCacheKeys?: never;
  onSelectedLifestylesSidebarOpen?: never;
  onSelectedLifestylesSidebarClose?: never;
  updateLifestylesSetCache?: never;
  onLifestylesSelect?: never;
}

type LifestylesListItemProps = LifestylesListItemDefaultProps &
  (LifestylesListItemWithSelectProps | LifestylesListItemWithoutSelectProps);

function LifestylesListItem({
  companyNanoId,
  isMyLibrary,
  lifestyle,
  lifestylesSet,
  lifestylesCacheKeys,
  lifestylesSetCacheKeys,
  onLightboxOpen,
  onSelectedLifestylesSidebarOpen,
  onSelectedLifestylesSidebarClose,
  updateLifestylesSetCache,
  updateLifestyleCache,
  onLifestylesSelect,
  onLifestyleEditButtonMouseEnter,
  withRealAspectRatio,
  withLifestylePreviewLink
}: LifestylesListItemProps) {
  const handleOpenLightbox = useCallback(() => {
    if (lifestyle.image) {
      onLightboxOpen(lifestyle.image);
    }
  }, [lifestyle, onLightboxOpen]);

  const isSelected = useMemo<boolean>(() => {
    if (!lifestylesSet || !lifestylesSet.selectedLifestyles) {
      return false;
    }
    return some(
      lifestylesSet.selectedLifestyles,
      (selectedLifestyle) =>
        selectedLifestyle?.lifestyle.uuid === lifestyle.uuid
    );
  }, [lifestyle, lifestylesSet]);

  const handleEditButtonMouseEnter = useCallback<() => void>(() => {
    onLifestyleEditButtonMouseEnter?.(lifestyle.nanoId);
  }, [onLifestyleEditButtonMouseEnter, lifestyle.nanoId]);

  let downloadButtonAction =
    LifestylesPermissions.READ_LIFESTYLE_DOWNLOAD_BUTTON;
  let lifestylePagePath = LifestylePath.show(lifestyle.nanoId);

  if (isMyLibrary) {
    lifestylePagePath = TeamPath.currentCompanyLibraryLifestyle(
      lifestyle.nanoId
    );
    downloadButtonAction =
      LifestylesPermissions.READ_SELF_COMPANY_LIBRARY_LIFESTYLE_DOWNLOAD_BUTTON;
  } else if (companyNanoId) {
    lifestylePagePath = TeamPath.companyLibraryLifestyle(
      companyNanoId,
      lifestyle.nanoId
    );
    downloadButtonAction =
      LifestylesPermissions.READ_OTHER_COMPANY_LIBRARY_LIFESTYLE_DOWNLOAD_BUTTON;
  }

  return (
    <div className="w-80 max-w-full flex flex-col mx-auto relative rounded-md group">
      <LifestylesListItemPreview
        lifestyle={lifestyle}
        lifestylePagePath={lifestylePagePath}
        onLightboxOpen={
          withLifestylePreviewLink ? undefined : handleOpenLightbox
        }
        withRealAspectRatio={withRealAspectRatio}
      />
      <div className="text-sm p-3 flex-1 flex flex-col">
        <div className="flex-1">
          <div className="font-medium truncate">
            <NextPureTooltipIconLinkHelper
              dataGa="lifestyles-lifestyle-name-link"
              href={lifestylePagePath}
              text={lifestyle.name}
              tooltipI18nText={lifestyle.name}
              tooltipPlacement={TooltipPlacement.BOTTOM}
            />
          </div>
          <div className="text-gray-600 dark:text-gray-500 truncate">
            <LifestylesListItemNda lifestyle={lifestyle} />
            <TextWithTooltip
              text={lifestyle.category?.localizedName}
              tooltipPlacement={TooltipPlacement.BOTTOM}
            />
          </div>

          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLE_UPLOADED}
          >
            <LifestylesListItemUploaded lifestyle={lifestyle} />
          </CheckPermissions>
        </div>

        <div className="mt-3 flex justify-between items-center relative z-5">
          {onSelectedLifestylesSidebarOpen ? (
            <LifestyleSelectButton
              lifestyle={lifestyle}
              lifestylesSet={lifestylesSet}
              lifestylesSetCacheKeys={lifestylesSetCacheKeys}
              onSelectedLifestylesSidebarOpen={onSelectedLifestylesSidebarOpen}
              onSelectedLifestylesSidebarClose={
                onSelectedLifestylesSidebarClose
              }
              updateLifestylesSetCache={updateLifestylesSetCache}
              onSelect={onLifestylesSelect}
              selectedClassName="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm text-white bg-blue-500 hover:bg-blue-600 border-transparent space-x-1"
              unselectedClassName="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 text-gray-700 hover:bg-gray-50"
            />
          ) : (
            <CheckPermissions action={downloadButtonAction}>
              <DownloadLifestyleButton
                lifestyleUuid={lifestyle.uuid}
                className="border font-medium hover:shadow inline-flex items-center px-2 py-1 relative rounded-md shadow-sm text-sm border-gray-300 dark:border-gray-700 dark:hover:bg-gray-700 dark:text-gray-300 text-gray-700 hover:bg-gray-50"
                tooltipI18nText={words.download}
                i18nText={words.download}
                tooltipSingleton
              />
            </CheckPermissions>
          )}

          <CheckPermissions
            action={LifestylesPermissions.READ_LIFESTYLE_FAVORITE_BUTTON}
          >
            <LifestyleFavoriteButton
              lifestyle={lifestyle}
              lifestylesSetCacheKeys={lifestylesSetCacheKeys}
              lifestylesCacheKeys={lifestylesCacheKeys}
              updateIndexLifestyleCache={updateLifestyleCache}
              className={
                lifestyle.favorite
                  ? 'focus:ring-offset-0 items-center rounded-full text-pink-600 hover:text-pink-500 flex p-0.5'
                  : 'focus:ring-offset-0 items-center rounded-full text-gray-400 dark:text-gray-500 hover:text-pink-600 dark:hover:text-pink-600 flex p-1'
              }
              icon={
                lifestyle.favorite
                  ? IconsEnum.HEART_SOLID
                  : IconsEnum.HEART_OUTLINE
              }
            />
          </CheckPermissions>
        </div>
      </div>

      {isSelected ? (
        <div className="absolute inset-0 rounded-md ring-4 ring-blue-500 bg-blue-500 opacity-10 pointer-events-none" />
      ) : null}

      {lifestyle.blocked ? (
        <div className="absolute inset-0 rounded-md bg-crossed bg-red-200 bg-opacity-10 pointer-events-none" />
      ) : null}

      <div className="absolute inset-0 pointer-events-none flex sm:opacity-0 group-hover:opacity-100 focus-within:opacity-100 items-start justify-end">
        <div className="flex flex-col space-y-1 pointer-events-auto p-2">
          <TooltipSingletonSourceWrapper
            placement={TooltipPlacement.LEFT}
            withArrow
          >
            <CheckPermissions action={downloadButtonAction}>
              <DownloadLifestyleButton
                lifestyleUuid={lifestyle.uuid}
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                icon={IconsEnum.DOWNLOAD_SOLID}
                iconClassName="h-5 w-5"
                tooltipI18nText={words.download}
                tooltipSingleton
              />
            </CheckPermissions>

            <CheckPermissions
              action={LifestylesPermissions.READ_LIFESTYLE_ATTACH_BUTTON}
            >
              <LifestyleAttachModalButton
                lifestyle={lifestyle}
                icon={IconsEnum.PAPER_CLIP_SOLID}
                iconClassName="h-5 w-5 stroke-1.75"
                tooltipI18nText={words.attach}
              />
            </CheckPermissions>

            <LifestylesListItemOpenLightboxButton
              lifestyle={lifestyle}
              onLightboxOpen={handleOpenLightbox}
            />
            <CheckPermissions
              action={
                LifestylesPermissions.READ_LIFESTYLE_UPDATE_IMAGE_VERSION_BUTTON
              }
            >
              <LifestylesListItemUpdateImageVersions lifestyle={lifestyle} />
            </CheckPermissions>

            <CheckPermissions
              action={LifestylesPermissions.READ_LIFESTYLE_COPY_LINK_BUTTON}
            >
              <LifestyleCopyLinkButton
                lifestyleNanoId={lifestyle.nanoId}
                icon={IconsEnum.LINK_SOLID}
                iconClassName="h-5 w-5 stroke-1.75"
                className="bg-white dark:bg-gray-900 dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0 font-medium hover:bg-gray-200 inline-flex items-center p-1 rounded-md text-gray-700 text-sm pointer-events-auto shadow dark:glow"
                tooltipI18nText={words.copyLink}
              />
            </CheckPermissions>

            <CheckPermissions
              action={LifestylesPermissions.READ_LIFESTYLE_EDIT_BUTTON}
            >
              <LifestylesListItemEditLink
                lifestyle={lifestyle}
                onMouseEnter={handleEditButtonMouseEnter}
              />
            </CheckPermissions>
          </TooltipSingletonSourceWrapper>
        </div>
      </div>
    </div>
  );
}

export default memo<LifestylesListItemProps>(LifestylesListItem);
